import React from 'react'
import Cart from '../components/Cart'
import Layout from '../components/Layout'

export default () => (
  <Layout themeType="dark" currentPage={'cart'}>
    <div>
      <Cart />
    </div>
  </Layout>
)
