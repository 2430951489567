import styled from 'styled-components'
import media from '../../styles/media'
import theme from '../../styles/theme'

export const CartSection = styled.div`
  display: flex;

  &.display-column {
    flex-direction: column;
  }

  .mobile-sep {
    display: none;
  }

  .box {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
  }

  .full-width {
    width: 100%;
  }

  .purchases-container {
    height: max-content;

    .item-container {
      display: flex;

      .event-picture {
        img {
          width: 80%;
        }
      }

      .item-description {
        font-family: DroidSerif;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.06;
        letter-spacing: normal;
        color: #c59d5f;

        .item-detail {
          font-size: 12px;
          letter-spacing: normal;
          color: ${theme.black};
        }

        .mt-1 {
          margin-top: 5px;
        }
      }

      .trash-mobile {
        display: none;
      }

      .trash-col-desktop {
        margin: 0 0 0 auto;
      }

      .item-quantity {
        padding-top: 1rem;
      }
    }
  }

  .title {
    height: 20px;
    font-family: Roboto;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000000;
    text-transform: uppercase;
  }

  .trash-can {
    font-size: 18px;
    color: #ff4040;
  }

  .item-prop {
    font-family: Roboto;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.08px;
    text-align: justify;
    color: ${theme.purpleBrown};

    &.col {
      padding-top: 1rem;
    }
  }

  .total {
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    &.colored {
      color: #c59d5f;
    }
  }

  .totals {
    .total-row {
      align-items: baseline;
      &.sub-total {
        margin-bottom: -1.5rem;
        margin-top: -0.5rem;
      }
      .sub-total-title {
        font-weight: normal;
      }
    }

    .next-btn {
      margin: 1rem 1.5rem 1.5rem;
      .next-button {
        .button-width {
          width: 100%;
        }
      }
    }
  }

  .hide-totals {
    display: none;
  }

  .hide-evt-btn {
    display: none;
  }

  ${media.sm`
    .box {
      margin-top: 2rem;
      padding: 1rem 0;
    }

    .purchases-container {

      .event-picture {
        width: 140px;
        height: 95px;
      }
      
      .item-container {
        margin: auto 0.5rem;
      }

      .item-description {
        font-size: 12px;
        padding-top: 1rem;
        padding-left: 0.5rem;
        width: 85px;
      }

      .item-quantity {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        .react-numeric-input {
          font-size: 10px
          input {
            width: 65px;
          }
        }
      }
    }

    .title {
      font-size: 10px;
      &.col {
        padding: 0 0.5rem 0 0.5rem;
        &.trash {
          padding-left: 1rem;
          cursor: pointer;
        }
      }

      &.row {
        margin: 1.5rem;
      }
    }

    .item-prop {
      font-size: 11px;
      &.col {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        white-space: nowrap;
      }

      &.row {
        margin-top: 1.5rem;
        margin-left: auto;
        margin-right: 0;
      }
    }

    .total {
      &.label {
        line-height: 1.11;
        letter-spacing: normal;
      }
      &.value {
        line-height: normal;
        letter-spacing: 1.38px;
        text-align: justify;
      }
    }
    
    .totals {
      height: 100%;
      width: 100%;
      margin-left: 1rem;

      .total-row {
        margin: 0 1.5rem;
        .total {
          font-size: 10px;
        }
      }
      .next-btn {
        #next-button {
          padding: 0.5rem;
        }  
      }
    }

    .empty-cart {
      padding-bottom: 1rem;
      width: 100%;
      text-align: center;
      font-size: 15px;
    }

    .display-evt-btn {
      margin-top: 2rem;
      text-align: center;
    }
  `}

  ${media.md`
    .box {
      margin-top: 3rem;
    }
    
    .purchases-container {
      min-width: 700px;
      margin-right: 1rem;

      .event-picture {
        width: 150px;
        height: 105px;
      }

      .item-container {
        margin: 0;
        padding: 1.5rem 1rem;

        .item-quantity {
          padding-left: 1rem;
          padding-right: 1rem;
          .react-numeric-input {
            font-size: 12px
            input {
              width: 75px;
            }
          }
        }
      }

      .item-description {
        font-size: 16px;
        padding-top: 1rem;
        padding-left: 1rem;
        width: 190px;
        height: 43px;
      }
    }

    .title {
      font-size: 14px;
      &.col {
        padding: 0 1rem;
        line-height: 1;
        &.trash {
          padding-left: 1rem;
        }
      }
    }

    .item-prop {
      font-size: 14px;
      &.col {
        padding-left: 1rem;
      }

      &.row {
        margin-top: 1.5rem;
        margin-left: auto;
        margin-right: 0;
      }
    }
    
    .totals {
      margin-left: 1rem;

      .total-row {
        margin: 1rem 1.5rem;
        .total {
          font-size: 15px;
        }
      }
    }
  `}

  ${media.lg`
    .purchases-container {
      min-width: 850px;
      margin-right: 1rem;

      .event-picture {
        width: 150px;
        height: 105px;
      }

      .item-container {
        margin: 0;
        padding: 1.5rem 2rem;

        .item-quantity {
          padding-left: 1rem;
          padding-right: 1rem;
          .react-numeric-input {
            font-size: 14px
            input {
              width: 90px;
            }
          }
        }
      }

      .item-description {
        font-size: 16px;
        padding-top: 1rem;
        padding-left: 1rem;
        width: 220px;
        height: 43px;
      }
    }

    .title {
      font-size: 14px;
      &.col {
        padding: 0 1rem;
        &.trash {
          padding-left: 1rem;
        }
      }
    }

    .item-prop {
      font-size: 14px;
      &.col {
        padding-left: 1rem;
      }

      &.row {
        margin-top: 1.5rem;
        margin-left: auto;
        margin-right: 0;
      }
    }
    
    .totals {
      margin-left: 1rem;

      .total-row {
        margin: 1rem 1.5rem;
        .total {
          font-size: 15px;
        }
      }
    }
    
    .empty-cart {
      font-size: 18px;
    }
  `}

  @media screen and (max-width: 767px) {
    flex-direction: column;

    .mobile-sep {
      display: block;
    }

    .desktop-sep {
      display: none;
    }

    .box {
      padding: 0 0.5rem;
    }

    .purchases-container {
      min-width: 100%;
      margin-top: 2rem;

      .item-container {
        flex-direction: column;
        padding: 0.5rem;
        .event-picture {
          display: none;
        }

        .title {
          &.col {
            margin: 0.5rem auto;
            padding: 0;
          }
        }

        .row-mobile {
          display: flex;
          .item-quantity {
            padding: 0;
          }
          .item-prop {
            text-align: end;
            padding-right: 0;
          }
        }

        .item-description {
          padding: 0rem;
        }

        .trash-mobile {
          display: block;
          margin: 0 0 0 auto;
          cursor: pointer;
        }

        .trash-col-desktop {
          display: none;
        }
      }
    }

    .totals {
      margin-top: 2rem;
      .total-row {
        margin: 0.5rem;
        .title {
          margin: 0.5rem 0;
        }
        .row {
          margin: 0 0 0 auto;
        }
      }
      .next-btn {
        margin: 1rem 0.5rem;
      }
    }

    .empty-cart {
      padding-bottom: 1rem;
      text-align: center;
    }

    .display-evt-btn {
      padding-top: 2rem;
    }
  }
`
