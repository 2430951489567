import axios from 'axios'
import { Link, navigate } from 'gatsby'
import React, { Component } from 'react'
import Modal from 'react-modal'
import AuthContext from '../../context/auth-context'
import Button from '../Button'
import { StyledButton } from '../Button/style'
import StockModal from '../Event/components/Modals/StockModal'
import IncreaseDecreaseNumber from '../Generics/IncreaseDecreaseNumber'
import PurchaseLayout from '../Layout/Purchase'
import { CartSection } from './style'

const headers = {
  'Content-Type': 'application/json',
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingLeft: 0,
    paddingRight: 0,
    width: '560px',
    textAlign: 'center',
  },
}
class Cart extends Component<{}, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      modal: {
        show: false,
        event: {
          total_tickets: '',
          slug: '',
        },
      },
    }
  }

  removeItem(item: any) {
    this.removeItemFromPurchase(item)
  }

  async removeItemFromPurchase(item: any) {
    const { purchase, setPurchase } = this.context
    await axios
      .post(`${process.env.API_URL}purchase/remove`, { purchaseId: purchase.id, itemId: item.id }, { headers })
      .then((response: any) => response.data)
      .then((purchaseData: any) => {
        if (purchaseData.isDeleted) {
          setPurchase(null)
          return
        }
        setPurchase({
          ...purchase,
          items: purchaseData.items,
          subtotal: purchaseData.subtotal,
          grande_table_subtotal: purchaseData.grande_table_subtotal,
          tax: purchaseData.tax,
          grande_table_tax: purchaseData.grande_table_tax,
          total: purchaseData.total,
          grande_table_total: purchaseData.grande_table_total,
          updated_at: purchaseData.updated_at,
        })
      })
  }

  updatePurchaseTotals = async () => {
    const { purchase, setPurchase } = this.context

    const grande_table_subtotals = purchase.items.map((currentItem: any) => {
      currentItem.grande_table_subtotal = currentItem.grande_table_price * currentItem.quantity
      return currentItem.grande_table_subtotal
    })

    const subtotals = purchase.items.map((currentItem: any) => {
      currentItem.subtotal = currentItem.unit_price * currentItem.quantity
      return currentItem.subtotal
    })

    purchase.grande_table_subtotal = grande_table_subtotals.reduce((a: number, b: number) => a + b, 0)
    purchase.subtotal = subtotals.reduce((a: number, b: number) => a + b, 0)
    purchase.grande_table_tax = purchase.grande_table_subtotal * purchase.tax_percent
    purchase.tax = purchase.subtotal * purchase.tax_percent
    purchase.grande_table_total = purchase.grande_table_subtotal + purchase.grande_table_tax
    purchase.total = purchase.subtotal + purchase.tax

    setPurchase(purchase)
    await axios.post(`${process.env.API_URL}purchase/edit`, purchase, { headers })
  }

  formattedNumber = (numberValue: any, style: string) => {
    return <div className={style}>$ {numberValue ? Number(numberValue).toFixed(2).replace('.', ',') : '0,00'}</div>
  }

  sendTotals = () => {
    const { user, purchase, setPurchase } = this.context

    const items = purchase.items.map((item: any) => ({
      id: item.id,
      description: item.description,
      quantity: item.quantity,
      unit_price: item.unit_price,
      grande_table_price: item.grande_table_price,
      item_type: this.defineEventType(item.item_type),
      item_type_id: item.item_type_id,
      sub_event_id: item.sub_event_id,
    }))

    const purchaseData = {
      id: purchase.id,
      customerId: user ? user.customerId : null,
      grande_table_subtotal: purchase.grande_table_subtotal,
      subtotal: purchase.subtotal,
      items,
    }

    axios
      .post(`${process.env.API_URL}purchase/edit`, purchaseData, { headers })
      .then((response: any) => response.data)
      .then((purchaseResponse: any) => setPurchase(purchaseResponse))
      .then(() => navigate('/invoice-data'))
      .catch((error: any) => {
        const message = error.response.data.message
        if (message) {
          message.forEach((response: any) => {
            const removableItem = items.filter((item: any) => item.item_type_id == response.event_id)
            this.setState({
              modal: {
                show: true,
                event: {
                  total_tickets: response.total_tickets,
                  slug: response.event_slug,
                },
              },
            })
            this.removeItem(removableItem[0])
          })
        }
      })
  }

  defineEventType = (type: string) => {
    switch (type) {
      case 'Evento':
        return 'event'
      default:
        return ''
    }
  }

  obtainEventsAndTickets = (): string => {
    const { purchase } = this.context
    const eventsTickets = purchase.items
      .map((item: any) => {
        return `[${item.description}:${item.quantity}]`
      })
      .join('_')
    return eventsTickets
  }

  hasPurchase = (): boolean => {
    const { purchase } = this.context
    return purchase && purchase.items && purchase.items.length > 0
  }

  closeModal = () => {
    const { modal } = this.state
    this.setState({ modal: { show: false, event: { ...modal.event } } })
    navigate(`events/${modal.event.slug}`)
  }

  setTextFormatToScheduledItem = (schedule: string) => {
    const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' }
    let formattedDate = new Date(schedule.substring(0, 10)).toLocaleDateString('es-EC', options)
    formattedDate = `${formattedDate.charAt(0).toUpperCase()}${formattedDate.slice(1)}`
    return `${formattedDate} ${schedule.substring(11, 16)}`
  }

  render() {
    const { purchase } = this.context
    const { modal } = this.state
    return (
      <PurchaseLayout title="Carrito de compras">
        <CartSection className={`${!this.hasPurchase() && 'display-column'}`}>
          <div className={`purchases-container box ${!this.hasPurchase() && 'full-width'}`}>
            {this.hasPurchase() ? (
              purchase.items.map((purchaseItem: any, index: any) => {
                return (
                  <div key={index}>
                    <div className="item-container">
                      <div className="event-picture">
                        <img src={purchaseItem.item.image} />
                      </div>
                      <div>
                        <div className="title col">{purchaseItem.item_type}</div>
                        <div className="row-mobile">
                          <div className="item-description">
                            {purchaseItem.description} <br /> <div className="item-detail mt-1">{purchaseItem.schedule}</div>
                          </div>
                          <div
                            className="trash-mobile"
                            onClick={() => {
                              this.removeItem(purchaseItem)
                            }}
                          >
                            <i className="icon-basurero trash-can" />
                          </div>
                        </div>
                      </div>
                      <hr className="mobile-sep" style={{ color: '#dfdfdf', margin: '0.5rem 0' }} />
                      <div className="row-mobile">
                        <div className="title col">CANTIDAD</div>
                        <div className="item-quantity">
                          <IncreaseDecreaseNumber item={purchaseItem} callback={this.updatePurchaseTotals} availableTickets={purchaseItem.item.total_tickets} />
                        </div>
                      </div>
                      <hr className="mobile-sep" style={{ color: '#dfdfdf', margin: '0.5rem 0' }} />
                      <div className="row-mobile">
                        <div className="title col">VALOR UNITARIO</div>
                        {this.formattedNumber(purchaseItem.unit_price, 'item-prop col')}
                      </div>
                      <hr className="mobile-sep" style={{ color: '#dfdfdf', margin: '0.5rem 0' }} />
                      <div className="row-mobile">
                        <div className="title col">SUBTOTAL</div>
                        {this.formattedNumber(purchaseItem.subtotal, 'item-prop col')}
                      </div>
                      <div className="trash-col-desktop">
                        <div
                          className="title col trash"
                          onClick={() => {
                            this.removeItem(purchaseItem)
                          }}
                        >
                          <i className="icon-basurero trash-can" />
                        </div>
                      </div>
                    </div>
                    {purchase.items[purchase.items.length - 1] !== purchaseItem ? (
                      <div>
                        <hr className="desktop-sep" style={{ color: '#dfdfdf', margin: '0 2rem' }} />
                        <hr className="mobile-sep" style={{ color: '#000000', margin: '0.5rem 0' }} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )
              })
            ) : (
              <div className="item-prop col empty-cart">No tienes compras realizadas</div>
            )}
          </div>

          <div className={`${this.hasPurchase() ? 'totals' : 'hide-totals'}`}>
            {this.hasPurchase() && (
              <>
                {purchase.subtotal !== 0 ? (
                  <div className="box">
                    <div className="total-row row">
                      <div className="title total label">TOTAL CON DINERS CLUB</div>
                    </div>
                    <hr className="desktop-sep" style={{ color: '#dfdfdf', margin: '0 1.5rem' }} />
                    <hr className="mobile-sep" style={{ color: '#dfdfdf', margin: '0 0.5rem' }} />
                    <div className="total-row row sub-total">
                      <div className="title sub-total-title">SUBTOTAL</div>
                      {this.formattedNumber(purchase.subtotal, 'item-prop row')}
                    </div>
                    <div className="total-row row">
                      <div className="title sub-total-title">IVA</div>
                      {this.formattedNumber(purchase.tax, 'item-prop row')}
                    </div>
                    <hr className="desktop-sep" style={{ color: '#dfdfdf', margin: '0 1.5rem' }} />
                    <hr className="mobile-sep" style={{ color: '#dfdfdf', margin: '0 0.5rem' }} />
                    <div className="total-row row">
                      <div className="title total label">TOTAL</div>
                      {this.formattedNumber(purchase.total, 'item-prop total value row')}
                    </div>
                  </div>
                ) : null}
                <div className="box">
                  <div className="total-row row">
                    <div className="title total label colored">TOTAL CON GRANDE TABLE</div>
                  </div>
                  <hr className="desktop-sep" style={{ color: '#dfdfdf', margin: '0 1.5rem' }} />
                  <hr className="mobile-sep" style={{ color: '#dfdfdf', margin: '0 0.5rem' }} />
                  <div className="total-row row sub-total">
                    <div className="title sub-total-title">SUBTOTAL</div>
                    {this.formattedNumber(purchase.grande_table_subtotal, 'item-prop row')}
                  </div>
                  <div className="total-row row">
                    <div className="title sub-total-title">IVA</div>
                    {this.formattedNumber(purchase.grande_table_tax, 'item-prop row')}
                  </div>
                  <hr className="desktop-sep" style={{ color: '#dfdfdf', margin: '0 1.5rem' }} />
                  <hr className="mobile-sep" style={{ color: '#dfdfdf', margin: '0 0.5rem' }} />
                  <div className="total-row row">
                    <div className="title total label colored">TOTAL</div>
                    {this.formattedNumber(purchase.grande_table_total, 'item-prop total value row colored')}
                  </div>
                </div>
                <div className="next-btn">
                  <Button id="GTABLE_EVENTCART_NEXT" link="" className="next-button" callback={() => this.sendTotals()} text="Siguiente" />
                </div>
              </>
            )}
          </div>

          <div className={!this.hasPurchase() ? 'full-width display-evt-btn' : 'hide-evt-btn'}>
            <Link id="next-button" to="/events">
              <StyledButton type="primary" link="" className="button-width">
                Comprar Eventos
              </StyledButton>
            </Link>
          </div>
        </CartSection>
        <Modal isOpen={modal.show} onRequestClose={() => this.closeModal} style={customStyles}>
          <StockModal event={modal.event} closeModal={this.closeModal} />
        </Modal>
      </PurchaseLayout>
    )
  }
}
Cart.contextType = AuthContext

export default Cart
